<script setup lang="ts">
const { user } = useCurrentUser()
const { logoutAndClearLocalStorage } = useAuth()
</script>

<template>
  <UAlert
    v-if="user?.isBeingImpersonated"
    class="absolute right-1/2 top-0 z-50 -mr-48 w-96"
    icon="light:user-secret"
    color="red"
    size="sm"
    variant="subtle"
    :ui="{ padding: 'p-2', rounded: 'rounded-t-none' }"
  >
    <template #title>
      <div class="flex items-center">
        <div class="line-clamp-1">
          <span class="font-bold">{{ user.display }}</span> ({{ user.email }}), {{ user.organizations[0].name }}
        </div>
        <UButton
          label="Log out"
          size="xs"
          variant="outline"
          color="red"
          class="ml-auto"
          @click="logoutAndClearLocalStorage"
        />
      </div>
    </template>
  </UAlert>
</template>
