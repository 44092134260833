<script setup lang="ts">
defineProps<{ visible: boolean }>()

function onEnter(_el: Element, done: () => void) {
  const el = _el as HTMLElement
  el.style.height = '0'
  // eslint-disable-next-line ts/no-unused-expressions
  el.offsetHeight // Trigger a reflow, flushing the CSS changes
  el.style.height = `${el.scrollHeight}px`

  el.addEventListener('transitionend', done, { once: true })
}

function onBeforeLeave(_el: Element) {
  const el = _el as HTMLElement
  el.style.height = `${el.scrollHeight}px`
  // eslint-disable-next-line ts/no-unused-expressions
  el.offsetHeight // Trigger a reflow, flushing the CSS changes
}

function onAfterEnter(_el: Element) {
  const el = _el as HTMLElement
  el.style.height = 'auto'
}

function onLeave(_el: Element, done: () => void) {
  const el = _el as HTMLElement
  el.style.height = '0'

  el.addEventListener('transitionend', done, { once: true })
}
</script>

<template>
  <Transition
    v-bind="{
      enterActiveClass: 'overflow-hidden transition-[height] duration-200 ease-out',
      leaveActiveClass: 'overflow-hidden transition-[height] duration-200 ease-out',
    }"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @before-leave="onBeforeLeave"
    @leave="onLeave"
  >
    <div v-if="visible">
      <slot />
    </div>
  </Transition>
</template>
