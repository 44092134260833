<script setup lang="ts">
import { useStorage } from '@vueuse/core'

const props = defineProps<{ workspaceId: string }>()

const { workspace } = useWorkspaceData(toRef(() => props.workspaceId))

const showNav = useStorage('showNav', true)
</script>

<template>
  <Transition>
    <div v-if="workspace" class="flex items-center p-4">
      <UButton
        icon="light:sidebar"
        variant="ghost"
        color="white-nav"
        data-action="hide-nav-button"
        @click="showNav = !showNav"
      />
      <div class="ml-3">
        {{ workspace.name }}
      </div>
    </div>
  </Transition>
</template>
