<script lang="ts" setup>
const { logoutAndClearLocalStorage } = useAuth()
const [inviteModalOpened, toggleInviteModal] = useToggle()

const { user } = useCurrentUser()

const dropdownItems = computed(() => [
  [{
    label: 'Settings',
    icon: 'light:gear',
    to: '/settings/user',
  }],
  [{
    label: 'Sign out',
    icon: 'light:arrow-right-from-bracket',
    click: logoutAndClearLocalStorage,
  },
  ],
])

const showWorkspaces = ref(false)
</script>

<template>
  <nav class="z-10 flex min-h-0 flex-1 flex-col justify-between gap-y-4 bg-blue-50 py-2 dark:bg-gray-950">
    <Transition>
      <NavigationWorkspace
        v-show="showWorkspaces"
        :organization-name="user?.organizations[0].name ?? ''"
        @loaded="showWorkspaces = true"
      />
    </Transition>

    <Transition>
      <div v-if="user && showWorkspaces" class="mr-4 flex flex-col gap-2">
        <UButton
          class="mx-2 justify-start px-2 font-normal"
          variant="ghost"
          color="white-nav"
          block
          @click="toggleInviteModal()"
        >
          <UIcon name="light:user-plus" />
          <span data-action="invite-users-nav-button">Invite users</span>
        </UButton>
        <UButton
          class="ml-2 justify-start px-2 font-normal"
          variant="ghost"
          color="white-nav"
          block
          to="mailto:support@sleuth.io?subject=Pulse%20Feedback"
          target="_blank"
        >
          <UIcon name="light:comments" />
          <span>Leave feedback</span>
        </UButton>
        <UButton
          class="ml-2 justify-start px-2 font-normal"
          variant="ghost"
          color="white-nav"
          block
          to="https://help.pulse.sleuth.io/"
          target="_blank"
        >
          <UIcon name="light:circle-question" />
          <span>Documentation</span>
        </UButton>
        <div class="group ml-2 flex w-full items-center rounded-md p-2 hover:bg-blue-80 dark:hover:bg-gray-900" data-testid="user-section">
          <UAvatar :src="user.gravatar" size="2xs" :ui="{ rounded: 'rounded-full' }" />
          <div class="ml-2 line-clamp-1 flex-1 overflow-hidden" :title="user.email">
            {{ user.display }}
          </div>
          <UDropdown
            :items="dropdownItems"
            :ui="{ width: 'w-56' }"
            :popper="{ offsetSkid: 4 }"
          >
            <UButton
              icon="light:ellipsis"
              size="2xs"
              color="gray"
              variant="nav"
              class="invisible group-hover:visible"
            />
          </UDropdown>
        </div>
      </div>
    </Transition>
    <SettingsOrgInviteModal v-model="inviteModalOpened" />
  </nav>
</template>
