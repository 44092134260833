<script setup lang="ts">
import { WsEventType } from '~/codegen/graphql'
import { getListOfNodes } from '~/utils/relay'
import { GetWorkspacesDocument } from './Workspace.generated'

const emit = defineEmits<{ loaded: [] }>()
const route = useRoute('teamspace-workspaceId')
const workspaceId = computed(() => route.params.workspaceId)
const { globalWorkspace: gWorkspace } = useGlobalWorkspace()

const { watchAndExecute } = useWebsocket()

const { data, isFetching, isDone, execute: updateWorkspaces } = useQuery({
  query: GetWorkspacesDocument,
  tags: ['workspaces'],
})

const events = [
  WsEventType.WorkspaceCreated,
  WsEventType.WorkspaceUpdated,
  WsEventType.WorkspaceRemoved,
]
watchAndExecute({ events, matcher: () => true, cb: () => updateWorkspaces({ cachePolicy: 'network-only' }) })

watch([isFetching, isDone], ([fetching, done]) => {
  if (!fetching && done)
    emit('loaded')
})

function workspaceToNavigationItem(workspace: { id: string, name: string }) {
  return {
    id: workspace.id,
    label: workspace.name,
    defaultOpen: workspaceId.value === workspace.id,
  }
}

const globalWorkspace = computed(() => {
  if (!gWorkspace.value)
    return
  return workspaceToNavigationItem(gWorkspace.value)
})

const favoriteWorkspaces = computed(() => {
  return (data.value?.user?.favoriteWorkspaces ?? []).map(workspaceToNavigationItem)
})

const nonGlobalWorkspaces = computed(() => {
  return getListOfNodes(data.value?.workspaces)
    .filter(w => !w.isGlobal)
    .filter(w => !favoriteWorkspaces.value.map(p => p.id).includes(w.id))
    .map(workspaceToNavigationItem)
})

const openFavoriteTeamspaces = ref(true)
const openAllTeamspaces = ref(false)
watch(favoriteWorkspaces, (newValue, oldValue) => {
  if (!isDone.value)
    return

  const currentWorkspaceFavoriteNow = newValue.some(w => w.id === workspaceId.value)
  const currentWorkspaceFavoriteBefore = oldValue.some(w => w.id === workspaceId.value)

  if (currentWorkspaceFavoriteNow && !currentWorkspaceFavoriteBefore)
    openFavoriteTeamspaces.value = true
  else if (!currentWorkspaceFavoriteNow || newValue.length === 0)
    openAllTeamspaces.value = true
})
</script>

<template>
  <div class="flex min-h-0 flex-1 flex-col">
    <div class="flex flex-col gap-y-10 overflow-y-auto">
      <div v-if="globalWorkspace">
        <div class="ml-4 text-sm text-gray-500 dark:text-gray-400">
          Organization
        </div>
        <NavigationItems
          :workspaces="[globalWorkspace]"
          :global="true"
          :global-workspace-id="globalWorkspace?.id ?? ''"
          :favorite-workspaces="false"
        />
      </div>

      <Details
        v-if="favoriteWorkspaces.length > 0"
        v-model="openFavoriteTeamspaces"
      >
        <template #summary>
          <div class="ml-4 py-1 text-sm text-gray-500 dark:text-gray-400">
            Favorites
          </div>
        </template>
        <NavigationItems
          :workspaces="favoriteWorkspaces"
          :global="false"
          :global-workspace-id="globalWorkspace?.id ?? workspaceId ?? ''"
          :favorite-workspaces="true"
        />
      </Details>
      <Details
        v-if="nonGlobalWorkspaces.length > 0"
        v-model="openAllTeamspaces"
      >
        <template #summary>
          <div class="ml-4 py-1 text-sm text-gray-500 dark:text-gray-400">
            Teamspaces
          </div>
        </template>
        <NavigationItems
          :workspaces="nonGlobalWorkspaces"
          :global="false"
          :global-workspace-id="globalWorkspace?.id ?? workspaceId ?? ''"
          :favorite-workspaces="false"
        />
      </Details>
    </div>

    <UDivider class="my-4" />

    <UButton
      icon="light:plus"
      data-action="new-teamspace-button"
      data-tour="new-teamspace-button"
      color="white-nav"
      label="New teamspace"
      variant="ghost"
      class="m-2 justify-start px-2 font-light text-gray-900 dark:text-white"
      :to="{ name: 'teamspace-new' }"
    />
  </div>
</template>
